import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { GSAdminLocale } from "@app/locales/localeid";
import {
    withRouter,
    GLForm,
    GLFormComponentProps,
} from "gl-commonui";
import { SecondaryTitle } from "@app/components";
import { FormView } from "./components/form-view";

interface RegionsCreatePageProps extends RouteComponentProps<any> { }
interface RegionsCreatePageStates {}

@withRouter
@GLForm.create()
export class RegionsCreatePage extends Component<
RegionsCreatePageProps & GLFormComponentProps,
RegionsCreatePageStates
> {
    constructor(props, context) {
        super(props, context);
    }
    render() {
        return (
            <div className="region-create content-layout">
                <SecondaryTitle title={GSAdminLocale.RegionsModelTitle} />
                <FormView 
                    isEdit={false}
                    history={this.props.history}
                />
            </div>
        );
    }
}
